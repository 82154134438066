import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryZoomContainer,
} from 'victory';
import { withSize } from 'react-sizeme';
import { challengeReflectionsColorScale } from '../../../../Styles';
import * as PropTypes from 'prop-types';
import { getChain } from '../../../../services/helpers';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../../utilities/localisation/translationKeys';

const root = {
  flexGrow: 1,
  backgroundColor: 'transparent',
  display: 'flex',
  height: 270,
};
const useStyles = makeStyles(() => ({
  root,
  grabber: {
    ...root,
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
}));

const WorkingLabelComponent = (props) => {
  return <VictoryLabel { ...props } text={ props.labels[props.ticks[props.index] - 1] } />;
};

WorkingLabelComponent.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
};

const SingleChallengeReflections = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let before = [];
  let after = [];
  let again = [];
  let dates = [];
  let dateStrings = [];
  // Sort the reflections as they don't necessarily come down in date order
  props.reflections && props.reflections.sort((a, b) => {
    if (a.date > b.date) {
      return 1;
    } else if (a.date < b.date) {
      return -1;
    } else {
      return 0;
    }
  });
  props.reflections && props.reflections.forEach((attempt, i) => {
    // use a count for the tick values and collect the dates
    before.push({ date: i + 1, score: attempt.results[0].score });
    after.push({ date: i + 1, score: attempt.results[1].score });
    again.push({ date: i + 1, score: attempt.results[2].score });
    dates.push(i + 1);
    let date = new Date(attempt.date);
    let dateString = date.toLocaleString();
    dateStrings.push(dateString);
  });
  let reorderedReflections = [before, after, again];
  let reflectionCount = props.reflections ? props.reflections.length : 0;
  const shouldScroll = getChain(props, 'reflections', 'length') > 4;
  return (
    <div className={ shouldScroll ? classes.grabber : classes.root }>
      <VictoryChart
        height={ 290 }
        width={ props.size.width + 200 }
        domainPadding={ { x: reflectionCount === 2 ? [200, 200] : [120, 120], y: [10, 10] } }
        padding={ { top: 25, bottom: 45, left: 45, right: 45 } }
        theme={ VictoryTheme.material }
        containerComponent={ <VictoryZoomContainer allowPan={ true } allowZoom={ false }
                                                   zoomDimension="x"
                                                   zoomDomain={ reflectionCount > 4 ? { x: [0.5, 4.5] } : undefined } /> }
      >
        <VictoryLegend x={ 35 } y={ 5 }
                       orientation="horizontal"
                       gutter={ 20 }
                       colorScale={ challengeReflectionsColorScale }
                       data={ [
                         { name: t(translationKey.LabelBeforeChallenge), symbol: { type: 'square' } },
                         { name: t(translationKey.LabelDuringChallenge), symbol: { type: 'square' } },
                         { name: t(translationKey.LabelIfAskedToDoItAgain), symbol: { type: 'square' } },
                       ] }
        />
        <VictoryAxis crossAxis
                     label={ (t(translationKey.LabelDateChallengeAttempted)) + (shouldScroll ? t(translationKey.LabelToViewMoreAttemptsInstructions) : '') }
                     tickLabelComponent={ <WorkingLabelComponent labels={ dateStrings } /> }
                     style={ { grid: { stroke: 'none' }, axisLabel: { padding: 30 } } }
        />
        <VictoryAxis dependentAxis crossAxis
                     tickValues={ [1, 2, 3, 4, 5] }
                     label={t(translationKey.LabelWorryScaleScore)}
                     style={ { axis: { stroke: 'none' }, ticks: { stroke: 'none' }, axisLabel: { padding: 30 } } }
        />
        { props.reflections &&
        <VictoryGroup
          offset={ 52 }
          colorScale={ challengeReflectionsColorScale }>
          { reorderedReflections.map((row, i) => {
            return <VictoryBar
              key={ i }
              barWidth={ 50 }
              data={ row }
              x={t(translationKey.LabelDate)}
              y={t(translationKey.LabelScore)}
            />
          })
          }
        </VictoryGroup> }
      </VictoryChart>
    </div>
  );
};

export default withSize()(SingleChallengeReflections);
